import React, { useCallback, useContext } from 'react';
import { Link } from '../../link';
import '../css/CustomInfiniteHit.scss';
import LocaleContext from '../../../context/locale';
import searchTranslations from '../translations/translations';
import { mapMimeTypeToExtension } from '../../../utils/functions';
import DocumentIcon from '../../../atoms/static-icons/document';
const HitComponent = ({ hit, insights }) => {
  const { langCode } = useContext(LocaleContext);
  const translations = searchTranslations[langCode];
  const { tipologia = '', categoria = '' } = hit;
  const breadcrumbJson = JSON.parse(hit.breadcrumb);

  const onClickedItem = useCallback(
    (itemType = 'Item') => {
      insights('clickedObjectIDsAfterSearch', {
        eventName: `${itemType} Clicked`,
        userToken: 'user',
      });
    },
    [insights]
  );

  const renderCategories = () => {
    if (typeof categoria === 'string') {
      return <span className='hit__category'>{categoria}</span>;
    }
    if (Array.isArray(categoria)) {
      return (
        <ul className='hit__category-list'>
          {categoria.map((c) => (
            <li key={c} className='hit__category-item'>
              <span className='hit__category'>{c}</span>
            </li>
          ))}
        </ul>
      );
    }
  };

  const url = (() => {
    try {
      const url = new URL(hit.url).pathname;
      return url;
    } catch (e) {
      return null;
    }
  })();

  const renderNav = () => {
    if (hit.tipologia === 'Document') {
      return (
        <nav className='hit__breadcrumb'>
          {breadcrumbJson?.itemListElement.map((item, index) => {
            return (
              <span className='hit__breadcrumb-item' key={item.item.name}>
                {item.item.name}
              </span>
            );
          })}
        </nav>
      );
    }
    return (
      <nav className='hit__breadcrumb'>
        {breadcrumbJson?.itemListElement
          // REVIEW: disordinato
          .map((item, index, arr) => {
            if (arr.length === index + 1) return null; // skip the last one as it's never used in the breadcrumb
            try {
              const url = new URL(item.item['@id'] || '');
              if (url.pathname && url.pathname !== '/') {
                // skip the home page
                return (
                  <span className='hit__breadcrumb-item' key={item.item.name}>
                    <Link to={url.pathname}>
                      <span>{item.item.name}</span>
                    </Link>
                  </span>
                );
              }
            } catch (e) {
              // URL throws TypeError
              return null;
            }

            return null;
          })
          .filter(Boolean)}
      </nav>
    );
  };

  const mimeTypeToRender =
    mapMimeTypeToExtension(hit.mimeType || '') ||
    hit.mimeType?.split('/')[1] ||
    '';

  const linkContent = (
    <article className='search__hit hit'>
      {hit.tipologia === 'Document' ? (
        <div className='hit__document'>
          <span className={'hit__document-icon'}>
            <DocumentIcon />
          </span>
          <span className='hit__document-mimetype'>{mimeTypeToRender}</span>
        </div>
      ) : (
        <img src={hit.image} alt='' className='hit__image'></img>
      )}
      <section className='hit__data'>
        <p className='hit__type-and-category'>
          {tipologia && (
            <span className='hit__type'>{translations[tipologia]}</span>
          )}

          {renderCategories()}
        </p>
        <h3 className='hit__title'>
          {/* <Highlight hit={hit} attribute='title' tagName='mark' /> */}
          {hit.title}
        </h3>
        {renderNav()}
      </section>
    </article>
  );

  return url ? (
    hit.tipologia === 'Document' ? (
      <a
        href={url}
        target='_blank'
        rel='noreferrer'
        onClick={() => onClickedItem('Document')}
      >
        {linkContent}
      </a>
    ) : (
      <Link to={url} onClick={() => onClickedItem('Page')}>
        {linkContent}
      </Link>
    )
  ) : (
    <div>{linkContent}</div>
  );
};

export { HitComponent };
export default HitComponent;
