import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import debounce from 'lodash.debounce';
import FeatherX from '../../../atoms/static-icons/feather-x';

const SearchBox = ({
  translations,
  autoFocus = false,
  debounceTime = 300,
  // currentRefinement, isSearchStalled,
  refine,
}) => {
  const inputRef = useRef();
  const [searchText, setSearchText] = useState('');
  const debouncedRefine = useMemo(
    () => debounce(refine, debounceTime),
    [refine, debounceTime]
  );
  const onChange = useCallback(
    (e) => {
      const currentText = e.target.value;
      setSearchText(currentText);
      if (currentText.length > 2) {
        debouncedRefine(currentText);
      }
    },
    [debouncedRefine]
  );
  const resetQuery = () => {
    setSearchText('');
    refine('');
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const onSubmit = useCallback((e) => e.preventDefault(), []);

  return (
    <div className='ais-SearchBox'>
      <form className='ais-SearchBox-form' noValidate onSubmit={onSubmit}>
        <input
          ref={inputRef}
          className='ais-SearchBox-input'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          placeholder={translations?.inputPlaceholder || ''}
          spellCheck='false'
          maxLength='512'
          type='search'
          value={searchText}
          onChange={onChange}
        />
        <button
          className='ais-SearchBox-reset'
          type='reset'
          title='Clear the search query.'
          hidden={!searchText.length}
          onClick={resetQuery}
        >
          <FeatherX />
        </button>
      </form>
    </div>
  );
};

export default connectSearchBox(SearchBox);
