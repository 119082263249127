import React, { useContext } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import LocaleContext from '../../../context/locale';
import { searchTranslations } from '../translations/translations';
import Square from '../../../atoms/static-icons/square';
import CheckSquare from '../../../atoms/static-icons/check-square';

import '../css/CustomRefinementList.scss';
import classNames from 'classnames';

const CustomRefinement = ({ items, currentRefinement, refine }) => {
  const { langCode } = useContext(LocaleContext);
  const translations = searchTranslations[langCode]; // useMemo?
  return items.length ? (
    <ul className='custom-refinement'>
      {items.map((item, index) => {
        const isActive = currentRefinement.some(
          (refinement) => refinement === item.label
        );
        return (
          <li key={item.label} className='custom-refinement__element'>
            <label className='custom-refinement__element-content'>
              <input
                readOnly={true}
                className='custom-refinement__input'
                type='checkbox'
                checked={isActive}
              ></input>
              <button
                className='custom-refinement__button'
                onClick={(e) => {
                  e.preventDefault();
                  refine(item.value);
                }}
              >
                <span className='custom-refinement__icon'>
                  {isActive ? <CheckSquare /> : <Square />}
                </span>
                <span
                  className={classNames('custom-refinement__label', {
                    checked: isActive,
                  })}
                >
                  {translations[item.label] || item.label} ({item.count})
                </span>
              </button>
            </label>
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default connectRefinementList(CustomRefinement);
