import { useState, useContext, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';

import LocaleContext from '../../context/locale';
const useAlgoliaSearch = () => {
  const { langCode } = useContext(LocaleContext);
  const [algoliaState, setAlgoliaState] = useState({});
  useEffect(() => {
    const ALGOLIA_INDEX_NAME =
      langCode === 'it-IT'
        ? process.env.GATSBY_ALGOLIA_INDEX_NAME
        : process.env.GATSBY_ALGOLIA_INDEX_NAME_ENG;
    const algoliaClient = algoliasearch(
      'F1NACE37JQ',
      '29fe04809fa3f9f84aa38ca33b5b262d'
    );
    const searchClient = {
      search(requests) {
        if (requests.every(({ params }) => params.query.length > 2)) {
          return algoliaClient.search(requests);
        } else {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        }
      },
    };
    setAlgoliaState({
      ALGOLIA_INDEX_NAME,
      searchClient,
    });
  }, [langCode]);

  return algoliaState;
};

export default useAlgoliaSearch;
