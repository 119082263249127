import React, { useContext, useEffect, memo } from 'react';
import LocaleContext from '../../../context/locale';
import {
  connectHitInsights,
  connectInfiniteHits,
} from 'react-instantsearch-dom';
import searchTranslations from '../translations/translations';
import HitComponent from './HitComponent';
import '../css/CustomInfiniteHit.scss';
import { Button } from '../../button/Button';
import aa from 'search-insights';

aa('init', {
  appId: 'F1NACE37JQ',
  apiKey: '29fe04809fa3f9f84aa38ca33b5b262d',
});

const HitWithInsights = connectHitInsights(aa)(HitComponent);

// Da prova euristica memo risparmia un paio di re-render
const InfiniteHits = memo(({ hits, hasMore, refineNext, setResults }) => {
  const { langCode } = useContext(LocaleContext);
  const translations = searchTranslations[langCode];

  useEffect(() => {
    setResults(hits.length);
  }, [hits, setResults]);

  return (
    <div className='ais-InfiniteHits'>
      <ul className='ais-InfiniteHits-list'>
        {hits.map((hit) => (
          <li className='ais-InfiniteHits-item' key={hit.objectID}>
            <HitWithInsights hit={hit} />
            {/* <HitComponent hit={hit} /> */}
          </li>
        ))}
      </ul>
      {!!hits.length && (
        <Button
          disabled={!hasMore}
          type='tertiary'
          text={translations.loadMore}
          className='ais-InfiniteHits-loadMore__custom'
          onClick={refineNext}
        >
          {translations.loadMore}
        </Button>
      )}
    </div>
  );
});
InfiniteHits.displayName = 'InfiniteHits';

export default connectInfiniteHits(InfiniteHits);
