import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { InstantSearch, Configure } from 'react-instantsearch-dom';
import SearchBox from './SearchBox';
import '../../../css/plugins/algolia-custom.scss';
import classNames from 'classnames';
import LocaleContext from '../../../context/locale';
import { searchTranslations } from '../translations/translations';
import { Wrapper } from '../../../utils/components';
import useWithMobileResizeListener from '../../../utils/hooks/useWithMobileResizeListener';
import { Button } from '../../button/Button';
import HitComponent from './HitComponent';
import Refinements from './RefinementsWidget';
import '../css/Search.scss';
import CustomInfiniteHits from './CustomInfiniteHits';
import useAlgoliaSearch from '../../../utils/hooks/useAlgoliaSearch';
import FeatherX from '../../../atoms/static-icons/feather-x';

export const Search = ({ onClose, hasScrolled }) => {
  const [showRefinement, setShowRefinement] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [hasResults, setResults] = useState(false);
  const [ready, setReady] = useState(false);
  const searchRef = useRef();
  const isMobile = useWithMobileResizeListener(768, 250);
  const { ALGOLIA_INDEX_NAME, searchClient } = useAlgoliaSearch();
  useEffect(() => {
    setReady(true);
  }, []);

  const setResultsCB = useCallback((resultsNumber) => {
    if (
      resultsNumber > 0 &&
      searchRef.current.scrollHeight > searchRef.current.clientHeight
    ) {
      setResults(true);
    } else setResults(false);
  }, []);

  useEffect(() => {
    !isMobile && setMobileFilterOpen(false);
  }, [isMobile]);

  const onSearchStateChange = useCallback((searchState) => {
    setShowRefinement(searchState.query?.length > 2);
    return searchState;
  }, []);

  const { langCode } = useContext(LocaleContext);
  const translations = searchTranslations[langCode];

  return (
    <div
      className={`search${hasResults ? ' with-results' : ''}`}
      ref={searchRef}
      style={
        hasScrolled
          ? {
              '--search-window': `calc(
        100vh - 135px
      )`,
            }
          : {
              '--search-window': `calc(
        100vh - 190px
      )`,
            }
      } // 135px is the height of scrolled top header (10px) and main header (125px)
      // 190px is the height of top header (65px) and main header (125px)
    >
      <Wrapper className={classNames('wrapper search__wrapper')}>
        {ready && (
          <InstantSearch
            searchClient={searchClient}
            onSearchStateChange={onSearchStateChange}
            indexName={ALGOLIA_INDEX_NAME}
          >
            <Configure clickAnalytics />
            <SearchBox
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus={true}
              debounceTime={350}
              translations={translations}
            />
            <div className='search__content'>
              {isMobile && showRefinement && (
                <Button
                  type='tertiary'
                  className='search__filters'
                  onClick={() => setMobileFilterOpen(!mobileFilterOpen)}
                  text={translations.filter}
                />
              )}
              <div>
                {showRefinement && (
                  <Refinements
                    isMobile={isMobile}
                    mobileFilterOpen={mobileFilterOpen}
                    setMobileFilterOpen={setMobileFilterOpen}
                    translations={translations}
                  />
                )}
              </div>
              <div className='search__hits'>
                <CustomInfiniteHits
                  hitComponent={HitComponent}
                  setResults={setResultsCB}
                />
              </div>
              <button className='search__close' onClick={onClose}>
                <FeatherX />
              </button>
            </div>
          </InstantSearch>
        )}
      </Wrapper>
    </div>
  );
};

export default Search;
