export const searchTranslations = Object.freeze({
  'it-IT': {
    loadMore: 'Mostra di più',
    inputPlaceholder: 'Cerca',
    filter: 'Filtra',
    page: 'Pagina',
    product: 'Prodotto',
    blog: 'Blog',
    typology: 'Tipologia',
    category: 'Categoria',
    Document: 'Documenti',
  },
  'en-US': {
    loadMore: 'Load more',
    inputPlaceholder: 'Search',
    filter: 'Filter',
    page: 'Page',
    product: 'Product',
    blog: 'Blog',
    typology: 'Typology',
    category: 'Category',
    Document: 'Documents',
  },
});

export default searchTranslations;
