import React from 'react';
import classNames from 'classnames';
import CustomRefinementList from './CustomRefinementList';
import FeatherX from '../../../atoms/static-icons/feather-x';

const Refinements = ({
  translations,
  isMobile,
  setMobileFilterOpen,
  mobileFilterOpen,
}) => {
  return (
    <div
      className={classNames('search__refinements', {
        'open-mobile': mobileFilterOpen,
      })}
    >
      <article className='search__refinements-list'>
        <section className='search__refinements-element'>
          <div className='search__refinements-name'>
            {translations.typology}
          </div>
          <CustomRefinementList attribute='tipologia' />
        </section>
        <section className='search__refinements-element'>
          <div className='search__refinements-name'>
            {translations.category}
          </div>
          <CustomRefinementList attribute='categoria' />
        </section>
      </article>

      {isMobile && (
        <button
          className='search__refinements-close'
          onClick={() => setMobileFilterOpen(false)}
        >
          <FeatherX className='search__refinements-close-icon' />
        </button>
      )}
    </div>
  );
};

export default Refinements;
